//=include jquery/dist/jquery.js

//=include @popperjs/core/dist/umd/popper.js
//=include js-cookie/dist/js.cookie.js
//=include bootstrap/dist/js/bootstrap.js
//=include jquery.easing/jquery.easing.js
//=include @fancyapps/ui/dist/fancybox.umd.js

//=include sweetalert2/dist/sweetalert2.js
//=include @selectize/selectize/dist/js/selectize.js
//=include select2/dist/js/select2.full.js

//=include @yzfe/qrcodejs/qrcode.js
//=include simple-uploader.js/dist/uploader.min.js
//=include simple-datatables/dist/umd/simple-datatables.js
//=include @shopify/draggable/lib/draggable.bundle.js
//=include inputmask/dist/inputmask.js

//=include clipboard/dist/clipboard.min.js

const clipboard = new ClipboardJS('[data-clipboard]');

clipboard.on('success', function(e) {
	let text = e.trigger.querySelector('.copy-button-text');

	if (text && text.dataset.copied) {
		text.textContent = text.dataset.copied;
	}

    // console.info('Action:', e.action);
    // console.info('Text:', e.text);
    // console.info('Trigger:', e.trigger);

    e.clearSelection();
});

clipboard.on('error', function(e) {
    // console.error('Action:', e.action);
    // console.error('Trigger:', e.trigger);
});
